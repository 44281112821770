import React from 'react'
import RefrigeratorImg from '../../assets/img/services/Refrigerator1.webp'
import WasherImg from '../../assets/img/services/wassher.webp'
import Dryer from '../../assets/img/services/Dryer.png'
import Dishwasher from '../../assets/img/services/Dishwasher1.webp'
import Range from '../../assets/img/services/project prism_color search archive_04f177ae626274bf4b0bcac07b1404212a078b3b.webp'
import Title from './PropsComponents/Title'
import ServiceProps from './PropsComponents/ServiceProps'
function HomeService() {
  const ServiceData = [
    { image: RefrigeratorImg, name: 'Refrigerator Repair' },
    { image: WasherImg, name: 'Washer Repair' },
    { image: Dryer, name: 'Dryer Repair' },
    { image: Dishwasher, name: 'Dishwasher Repair' },
    { image: Range, name: 'Range/Cooktop/Stove Repair' }
  ]

  return (
    <>
      <div
        id={'Service'}
        className='w-full px-6 Block__Padding bg-[#545554] max-xl:px-6'
      >
        <div className='mx-auto  max-xl:px-6'>
          <div className='text-white'>
            <Title title={'Our Services'} />
          </div>
          <div className='flex flex-wrap lg:flex-nowrap lg:flex-row lg:space-x-2 xl:space-x-6'>
            {ServiceData &&
              ServiceData.map((item, index) => {
                return (
                  <ServiceProps
                    image={item.image}
                    name={item.name}
                    key={index}
                  />
                )
              })}
          </div>
        </div>
      </div>
    </>
  )
}

export default HomeService
