import React from 'react'
function PriceSmall (props) {
  return (
    <>
      <div>
        <div className='flex justify-between'>
          <div className='font-bold flex-shrink-0 font-outfit text-[14px] sm:text-[17px] text-[#14100c]'>
            {props.title}
          </div>
        </div>
      </div>
    </>
  )
}
export default PriceSmall
