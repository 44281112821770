import $ from 'jquery';

$('#Navbar').css("z-index" , "999999999999999999")
$('#VideoABS').css("z-index" , "999999999999999999")
$('.ankap').css('background' , 'white')
$('.ankap').css('font-size' , '30px' ,'important')
// $(document).ready(function() {

//     $(window).scroll(function() {
  
//       var scroll = $(window).scrollTop();
  

//         if(scroll > 5){
//             $('#Navbar').addClass('animate-info1')
//         }else{
//             $('#Navbar').removeClass('animate-info1')
//         }
//     });
//   });


  if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
    document.documentElement.classList.add('dark')
  } else {
    document.documentElement.classList.remove('dark')
  }
  
  // Whenever the user explicitly chooses light mode
  localStorage.theme = 'light'
  
  // Whenever the user explicitly chooses dark mode
  localStorage.theme = 'dark'
  
  // Whenever the user explicitly chooses to respect the OS preference
  localStorage.removeItem('theme')