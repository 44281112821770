export default function ServiceProps(props) {
    return (
        <>
         <div className='w-full max-lg:px-3 sm:w-1/2 max-lg:mt-4 lg:w-1/5  group'>
              <div className='overflow-hidden w-full h-[190px] flex justify-center'>
                <img src={props.image} className='transition-all duration-300 w-full  h-full object-cover group-hover:scale-105' alt='' />
              </div>
              <div className='xl:font-bold font-outfit text-center text-white mt-4 text-[15px] xl:text-[20px]'>
                {props.name}
              </div>
            </div>
        </>
    )
}