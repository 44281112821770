import React from 'react'
import HomeFirst from './Components/homeFirst'
import HomeAbout from './Components/homeAbout'
import HomeService from './Components/homeService'
import HomeOpinion from './Components/homeOpinion'
import HomePrice from './Components/homePrice'
import HomeOffer from './Components/homeOffer'
import HomeContact from './Components/homeContact'
import HomeWorks from './Components/HomeWorks'

function Home () {
  return (
    <>
      <HomeFirst />
      <HomeAbout />
      <HomeService />
      <HomeContact />
      <HomeOpinion />
      <HomePrice />
      <HomeWorks />
      <HomeOffer />
    </>
  )
}

export default Home
