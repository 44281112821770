export default function Title (props) {
  return (
    <>
      <div className='w-full'>
        <h2 className='text-center font-outfit font-bold  text-[24px] md:text-[34px] leading-none xl:text-[48px] pb-4 sm:pb-12 tracking-[1px] m-0'>
          {props.title}
        </h2>
      </div>
    </>
  )
}
