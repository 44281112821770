import Slider from 'react-slick'
import Img1 from '../../assets/img/slider/1.webp'
import Img2 from '../../assets/img/slider/2.webp'
import Img3 from '../../assets/img/slider/3.webp'
import Img4 from '../../assets/img/slider/4.webp'
import Img5 from '../../assets/img/slider/5.webp'
import Img6 from '../../assets/img/slider/6.webp'
import Img7 from '../../assets/img/slider/7.webp'
import Img8 from '../../assets/img/slider/8.webp'
import Img9 from '../../assets/img/slider/9.webp'
import Img10 from '../../assets/img/slider/10.webp'
import Img11 from '../../assets/img/slider/11.webp'
import Img12 from '../../assets/img/slider/12.webp'
import Title from './PropsComponents/Title'
export default function HomeWorks() {
  const Images = [
    { img: Img1 },
    { img: Img2 },
    { img: Img3 },
    { img: Img4 },
    { img: Img5 },
    { mg: Img6 },
    { mg: Img7 },
    { mg: Img8 },
    { mg: Img9 },
    { mg: Img10 },
    { img: Img11 },
    { img: Img12 }
  ]
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    arrows: false,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 940,
        settings: {
          arrows: false,
          slidesToShow: 3
        }
      },
      {
        breakpoint: 720,
        settings: {
          arrows: false,
          slidesToShow: 2
        }
      },
      {
        breakpoint: 500,
        settings: {
          arrows: false,
          slidesToShow: 1
        }
      }
    ]
  }
  return (
    <>
      <div className='w-full Block__Padding bg-white'>
        <div className='relative z-50 max-w-6xl  mx-auto max-xl:px-6'>
          <div className='text-main'>
            <Title title='Our  Gallery' />
          </div>
          <div className='w-full max-sm:pb-8'>
            <Slider {...settings}>
              <div className='h-full p-4'>
                <img
                  src={Img1}
                  className={'w-full h-[286px] sm:w-[256px] sm:h-[304px] object-cover'}
                />
              </div>
              <div className='h-full p-4'>
                <img
                  src={Img2}
                  className={'w-full h-[286px] sm:w-[256px] sm:h-[304px] object-cover'}
                />
              </div>
              <div className='h-full p-4'>
                <img
                  src={Img3}
                  className={'w-full h-[286px] sm:w-[256px] sm:h-[304px] object-cover'}
                />
              </div>
              <div className='h-full p-4'>
                <img
                  src={Img4}
                  className={'w-full h-[286px] sm:w-[256px] sm:h-[304px] object-cover'}
                />
              </div>
              <div className='h-full p-4'>
                <img
                  src={Img5}
                  className={'w-full h-[286px] sm:w-[256px] sm:h-[304px] object-cover'}
                />
              </div>
              <div className='h-full p-4'>
                <img
                  src={Img6}
                  className={'w-full h-[286px] sm:w-[256px] sm:h-[304px] object-cover'}
                />
              </div>
              <div className='h-full p-4'>
                <img
                  src={Img7}
                  className={'w-full h-[286px] sm:w-[256px] sm:h-[304px] object-cover'}
                />
              </div>
              <div className='h-full  p-4'>
                <img
                  src={Img8}
                  className={'w-full h-[286px] sm:w-[256px] sm:h-[304px] object-cover'}
                />
              </div>
              <div className='h-full p-4'>
                <img
                  src={Img9}
                  className={'w-full h-[286px] sm:w-[256px] sm:h-[304px] object-cover'}
                />
              </div>
              <div className='h-full p-4'>
                <img
                  src={Img10}
                  className={'w-full h-[286px] sm:w-[256px] sm:h-[304px] object-cover'}
                />
              </div>
              <div className='h-full p-4'>
                <img
                  src={Img11}
                  className={'w-full h-[286px] sm:w-[256px] sm:h-[304px] object-cover'}
                />
              </div>
              <div className='h-full p-4'>
                <img
                  src={Img12}
                  className={'w-full h-[286px] sm:w-[256px] sm:h-[304px] object-cover'}
                />
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </>
  )
}
