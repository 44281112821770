import React , {useState , useEffect} from "react";
import Home from "./Pages/home";
import Header from "./Pages/layouts/header";
import "./assets/js/script"
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Routes ,Route} from "react-router-dom";
import Footer from "./Pages/layouts/footer";
function App() {
 

  return (
    <>
   
        <Header />

        <Routes>
          <Route path="" element={<Home />} />
        </Routes>
        <Footer />
    </>
  );
}

export default App;
