import React, { useRef } from 'react'
import { AiFillStar } from 'react-icons/ai'
import Show1 from '../../assets/img/Repair/logo-expand-rouge123.jpg'
import '../../assets/js/script'
import emailjs from '@emailjs/browser'
import Title from './PropsComponents/Title'
function HomeContact() {
  const form = useRef()

  const sendEmail = e => {
    e.preventDefault()

    emailjs
      .sendForm(
        'service_nspckb4',
        'template_tmh0mhi',
        form.current,
        '4Z0PRbEIEIeZoW8PJ'
      )
      .then(
        result => {
          console.log(result.text)
        },
        error => {
          console.log(error.text)
        }
      )
    form.current.reset()
  }
  return (
    <>
      <div
        className='w-full pt-24 bg-white bg-cover bg-center relative'
        id='Book'
      >
        <div className='text-main'>
          <Title title="Schedule Your Appointment" />
        </div>
        <div className='max-w-6xl max-xl:px-6 mx-auto relative z-20 flex max-md:flex-col'>
          <div className='md:w-1/2 flex flex-col justify-center space-y-6'>
            <div>
              <ul className='flex space-x-1'>
                <li className='text-main'>
                  <AiFillStar />
                </li>
                <li className='text-main'>
                  <AiFillStar />
                </li>
                <li className='text-main'>
                  <AiFillStar />
                </li>
                <li className='text-main'>
                  <AiFillStar />
                </li>
                <li className='text-main'>
                  <AiFillStar />
                </li>
              </ul>
            </div>
            <div className='text-black sm:text-[27px] font-bold font-outfit sm:pr-24'>
              Your trust is our priority
            </div>
            <div className='flex sm:space-x-6'>
              <div className='max-sm:hidden'>
                <img src={Show1} className=' w-64 ' />
              </div>
              <div className='flex flex-col justify-center'>
                <p className='uppercase text-[12px] font-outfit font-bold text-main tracking-[2px]'>
                  Call Now
                </p>
                <p className='uppercase sm:text-[27px] font-outfit font-bold text-black tracking-[2px]'>
                  <a href="tel:8189639696">818 963 9696</a>
                </p>
              </div>
            </div>
          </div>
          <div className='md:w-1/2 '>
            <div className=' max-w-md bg-[#f5eee7] pt-[25px] pb-[25px] sm:pt-[45px] sm:pb-[60px] px-[10px] sm:px-[30px] relative -bottom-8 sm:-bottom-16'>
              <h1 className='text-[#14100c] sm:text-[27px] font-outfit font-bold text-center'>
                Make An Appointment
              </h1>
              <div className=''>
                <form ref={form} onSubmit={sendEmail}>
                  <div className='flex flex-col sm:flex-row justify-between sm:space-x-6'>
                    <div className='sm:w-1/2 mt-4'>
                      <input
                        type='text'
                        name='name'
                        placeholder='Name'
                        minLength={3}
                        required
                        className='text-[15px] text-[#14100c] placeholder:text-[#14100c] bg-white p-[15px] focus:outline-none w-[100%]'
                      />
                    </div>
                    <div className='sm:w-1/2 mt-4'>
                      <input
                        type='text'
                        name='phone'
                        placeholder='Phone'
                        minLength={10}
                        required
                        className='text-[15px] text-[#14100c] placeholder:text-[#14100c] bg-white p-[15px] focus:outline-none w-[100%]'
                      />
                    </div>
                  </div>
                  <div className='flex flex-col sm:flex-row justify-between sm:space-x-6 sm:mt-6'>
                    <div className='sm:w-1/2 mt-4'>
                      <input
                        type='date'
                        name='date'
                        placeholder='Date'
                        className='text-[15px] text-[#14100c] placeholder:text-[#14100c] bg-white p-[15px] focus:outline-none w-[100%]'
                      />
                    </div>
                    <div className='sm:w-1/2 mt-4'>
                      <input
                        type='time'
                        name='time'
                        placeholder=''  
                        className='text-[15px] text-[#14100c] placeholder:text-[#14100c] bg-white p-[15px] focus:outline-none w-[100%]'
                      />
                    </div>
                  </div>
                  <div className='flex flex-row justify-between space-x-6 mt-6'>
                    <div className='w-full'>
                      <textarea
                        className='w-full focus:outline-none p-[15px]'
                        name='text'
                        required
                        minLength={10}
                        placeholder='Describe the problem or type of service needed'
                      ></textarea>
                    </div>
                  </div>
                  <div className='mt-6'>
                    <button
                      type='submit'
                      className='text-[12px] font-bold uppercase tracking-[2px] font-outfit w-full bg-main text-white transition-all duration-300 hover:bg-[#14100c] hover:text-white h-[52px]'
                    >
                      Book Appointment
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='w-full py-12 bg-[#91765a]'></div>
    </>
  )
}

export default HomeContact
