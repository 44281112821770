import React from "react";

function DasSmall(props) {
    return(
        <>
             <div>
                <div className="flex justify-between mt-2">
                    <div className="w-1/4 font-outfit text-right pr-2 text-[12px] xl:text-[15px] text-[#000]">{props.title}</div>
                    <div className="Dotted-After w-2/4  max-sm:hidden relative after:absolute flex items-center after:w-[100%] after:h-auto "></div>
                    <div className="font-outfit w-1/2  pl-2 text-black text-[12px] xl:text-[15px]">{props.time}</div>
                </div>
            </div>
        </>
    )
}

export default DasSmall